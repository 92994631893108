import { Link, useFetcher } from '@remix-run/react'
import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import type { ExtendedUser } from '~/modules/account/components/AccountLayout/loader'
import { getBullets } from '~/modules/signup/pages/SignUpStep1SelectPlanPage'
import useMediaQuery from '~/hooks/useMediaQuery'
import useRootData from '~/hooks/useRootData'
import Button from '~/components/Button'
import RadioCards from '~/modules/signup/components/RadioCard'
import TextInput from '~/components/forms/TextInput'

import type { Errors } from '~/routes/api.account.sign-up-in-one'

interface FetcherData {
  user?: Partial<ExtendedUser>
}

interface SignUpEmailFormProps {
  inOne?: boolean
  className?: string
  user?: ExtendedUser
}
const SignUpEmailForm = ({ inOne, className, user }: SignUpEmailFormProps) => {
  const root = useRootData()
  const checker = useFetcher<FetcherData>()
  const submitter = useFetcher<Errors>()

  const hasSubmitted = !!checker.data
  const hasAccount = !!checker.data?.user

  const isMobile = useMediaQuery('(max-width: 640px)')

  const [email, setEmail] = useState('')

  const isInvalid = !email.length || !/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)

  const handleCheckEmail = useDebouncedCallback((currentEmail: string) => {
    checker.submit({ email: currentEmail }, { method: 'post', action: '/api/account/exists' })
  }, 350)

  const errors = submitter.data

  return root?.user && !user ? (
    <div className={className}>
      <p className="text-lg font-bold">You already have an account, however your subscription has lapsed.</p>
      <Button to="/cub-club/account/subscription" text="Manage your account" />
    </div>
  ) : (
    <submitter.Form method="post" className={className} action={inOne ? '/api/account/sign-up-in-one' : undefined}>
      {user?.customerId && <input type="hidden" name="customerId" value={user.customerId} />}
      {user?.email ? (
        <input type="hidden" name="email" value={user.email} />
      ) : (
        <TextInput
          type="email"
          name="email"
          label="Email"
          placeholder="pevan@thejungle.com"
          autoComplete="email"
          onChange={event => {
            handleCheckEmail(event.target.value)
            setEmail(event.target.value)
          }}
          showInvalidIcon={hasSubmitted && isInvalid}
          showValidIcon={hasSubmitted && !isInvalid}
          error={(hasSubmitted && isInvalid && 'Please enter a valid email address.') || undefined}
          required
        />
      )}

      {errors?.email && <p className="text-pink">{errors?.email}</p>}

      {inOne && (
        <RadioCards name="frequency" commonBullets={getBullets(isMobile, false)} defaultValue="annual">
          <RadioCards.Card
            tagLine="Monthly subscription"
            header="$9.99 / month"
            bullets={getBullets(!isMobile, false)}
            id="monthly"
            value="monthly"
          />
          <RadioCards.Card
            tagLine="Annual subscription"
            header="$99 / year"
            bullets={getBullets(!isMobile, true)}
            id="annual"
            value="annual"
          />
        </RadioCards>
      )}
      {hasAccount ? (
        <Button to="/cub-club/login" intent="info" text="Account exists already. Log in?" size="lg" />
      ) : (
        <>
          {!user && (
            <>
              <label className="flex items-center">
                <input type="checkbox" name="newsletter" className="mr-2" defaultChecked />
                <span className="text-sm">Send me the latest from Pevan & Sarah</span>
              </label>

              <label className="flex items-center">
                <input type="checkbox" name="termsAgreement" className="mr-2" defaultChecked={false} />
                <span className="text-sm">
                  I have read and agree to the Pevan & Sarah{' '}
                  <Link to="/terms" className="font-bold text-blue">
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link to="/privacy" className="font-bold text-blue">
                    Privacy Policy
                  </Link>{' '}
                  and acknowledge that subscriptions will automatically renew until cancelled.
                </span>
              </label>

              {errors?.termsAgreement && <p className="text-pink">{errors?.termsAgreement}</p>}
            </>
          )}
          <Button
            intent="success"
            size="lg"
            text={user ? 'Renew subscription' : 'Start 7 day free trial'}
            className="flex-1"
            isLoading={submitter.state === 'submitting' || submitter.state === 'loading'}
          />

          {!user && (
            <div className="flex flex-1 flex-row items-center justify-center space-x-1">
              <span className="text-grey dark:text-light">Already have an account?</span>
              <Link
                to="/cub-club/login"
                prefetch="intent"
                className="px-1 py-0.5 font-bold hover:bg-opacity-50 hover:underline">
                Log in
              </Link>
            </div>
          )}
        </>
      )}
    </submitter.Form>
  )
}

export default SignUpEmailForm
